import {
  Box,
  Typography,
  Container,
  List,
  ListItem,
  IconButton,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PhoneIcon from "@mui/icons-material/Phone";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import helpline from "../public/helpline.jpg";
import Mobile from "./Mobile";

const CustomerService = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/account");
  };

  return (
    <Mobile>
      <Container
        disableGutters
        maxWidth="xs"
        sx={{
          bgcolor: "#f5f5f5",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            bgcolor: "#4781ff",
            padding: "8px 10px",
            display: "flex",
            alignItems: "center",
            color: "white",
          }}
        >
          <ChevronLeftIcon
            sx={{ fontSize: 30, cursor: "pointer" }}
            onClick={handleBackClick}
          />

          <Typography
            variant="h6"
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
            }}
          >
            Customer Service
          </Typography>
          <HomeIcon sx={{ fontSize: 30 }} />
        </Box>

        <Box>
          <Box
            component="img"
            src={helpline}
            alt="About us illustration"
            sx={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Box>

        <List sx={{ bgcolor: "white", m: 2, borderRadius: 2 }}>
          <ListItem
            button // Add button prop to make it clickable
            onClick={() => navigate("/support")} // Navigate to /support on click
            secondaryAction={
              <IconButton edge="end" aria-label="go to">
                <ChevronRightIcon />
              </IconButton>
            }
          >
            <ListItemIcon>
              <Box
                sx={{
                  bgcolor: "red",
                  borderRadius: "50%",
                  width: 35,
                  height: 35,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PhoneIcon sx={{ color: "white" }} />
              </Box>
            </ListItemIcon>
            <ListItemText
              primary="LiveChat"
              primaryTypographyProps={{ fontWeight: "medium" }}
            />
          </ListItem>
        </List>
      </Container>
    </Mobile>
  );
};

export default CustomerService;
