import AdminPanel from "./Admin";
import SubordinateGraph from "./SubordinateGraph";

const Subordinate = ({}) => {
  return (
    <AdminPanel>
      <SubordinateGraph />
    </AdminPanel>
  );
};

export default Subordinate;