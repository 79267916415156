import AdminPanel from './Admin';
import AdminTaskControl from "./LuckySpinAdmin"


const Luckyspinmain = ({}) => {
    return (
      <AdminPanel>
        <AdminTaskControl />
      </AdminPanel>
    );

  };
  
  export default Luckyspinmain;