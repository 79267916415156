import AdminPanel from './Admin';
import BannedMembersContent from './BannedMembersContent';


const BannedMembers = ({}) => {
  return (
    <AdminPanel>
      <BannedMembersContent />
    </AdminPanel>
  );
};

export default BannedMembers;