// **************LocalHost******************

// export const domain = "http://localhost:4002";
// export const wssdomain = "ws://localhost:4002";

// **************Production******************

// export const domain = 'https://aviatorbet.online';
// export const wssdomain = 'wss://aviatorbet.online';

// **************Production2******************

// export const domain = 'https://demo5.anotechsolutions.cloud';
// export const wssdomain = 'wss://demo5.anotechsolutions.cloud';

// **************Production3******************


 export const domain = 'https://aviatorkstkpro.site';
 export const wssdomain = 'wss://aviatorkstkpro.site';